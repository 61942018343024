# Declare app level module which depends on filters, and services

app = angular.module("questionnaireApp", ["questionnaireApp.filters","questionnaireApp.services", "questionnaireApp.directives", "questionnaireApp.controllers","ngRoute","ngCookies","firebase","kendo.directives","fsm"])

app.factory 'Auth', ['$firebaseAuth', ($firebaseAuth) ->
    $firebaseAuth()
]

app.config ["$locationProvider","$routeProvider", ($locationProvider, $routeProvider) ->
    $locationProvider.hashPrefix ''
    $routeProvider.when('/autologin/:autologin/unsubscribe', {
        templateUrl: "partials/autologin.html"
        controller: "AutologinController"
    })
    $routeProvider.when('/autologin/:autologin/question/:question/answer/:answer', {
        templateUrl: "partials/autologin.html"
        controller: "AutologinController"
    })
    $routeProvider.when('/autologin/:autologin/q1/:q1/a1/:a1/q2/:q2/a2/:a2/q3/:q3/a3/:a3', {
        templateUrl: "partials/autologin.html"
        controller: "AutologinController"
    })
    $routeProvider.when('/autologin/:autologin/q1/:q1/a1/:a1/q2/:q2/a2/:a2', {
        templateUrl: "partials/autologin.html"
        controller: "AutologinController"
    })
    $routeProvider.when('/autologin/:autologin/q1/:q1/a1/:a1', {
        templateUrl: "partials/autologin.html"
        controller: "AutologinController"
    })
    $routeProvider.when('/autologin/:autologin', {
        templateUrl: "partials/autologin.html"
        controller: "AutologinController"
    })
    $routeProvider.when('/anonymouslogin/questionnaire/:questionnaire/language/:language/q1/:q1/a1/:a1/q2/:q2/a2/:a2/q3/:q3/a3/:a3',  {
        templateUrl: "partials/autologin.html"
        controller: "AnonymousLoginController"
    })
    $routeProvider.when('/anonymouslogin/questionnaire/:questionnaire/language/:language/q1/:q1/a1/:a1/q2/:q2/a2/:a2',  {
        templateUrl: "partials/autologin.html"
        controller: "AnonymousLoginController"
    })
    $routeProvider.when('/anonymouslogin/questionnaire/:questionnaire/language/:language/q1/:q1/a1/:a1',  {
        templateUrl: "partials/autologin.html"
        controller: "AnonymousLoginController"
    })
    $routeProvider.when('/anonymouslogin/questionnaire/:questionnaire/language/:language',  {
        templateUrl: "partials/autologin.html"
        controller: "AnonymousLoginController"
    })
    $routeProvider.when('/unsubscribe',  {
        templateUrl: "partials/unsubscribe.html"
        controller: "UnsubscribeController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when('/upgrade',  {
        templateUrl: "partials/upgrade.html"
        controller: "UpgradeController"
    })
    $routeProvider.when('/notauthorized',  {
        templateUrl: "partials/notauthorized.html"
        controller: "NotAuthorizedController"
    })
    $routeProvider.when('/thankyou/:language', {
        templateUrl: "partials/thankyou.html"
        controller: "ThankYouController"
    })
    $routeProvider.when('/login',  {
        templateUrl: "partials/login.html"
        controller: "LoginController"
    })
    $routeProvider.when('/preview/questionnaire/:questionnaire/language/:language',  {
        templateUrl: "partials/questionnaire/questionnaire.html"
        controller: "PreviewController"
    })
    $routeProvider.when( "/questionnaire/:questionnaire/language/:language", {
        templateUrl: "partials/questionnaire/questionnaire.html"
        controller: "QuestionnaireController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when( "/questionnaire", {
        templateUrl: "partials/questionnaire/questionnaire.html"
        controller: "QuestionnaireController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })

    $routeProvider.otherwise({redirectTo: '/login'})
]

app.run ($rootScope, $location) ->
    $rootScope.$on '$routeChangeStart', (event, next, current) ->
        return
    $rootScope.$on '$routeChangeError', (event, next, previous, error) ->
        if error == "AUTH_REQUIRED"
            $location.url "/login"
        return

